<template>
  <div class="form">
    <p class="form__title">会員登録をしている全ユーザーにメールを送信します。<br>以下の枠に送信したい内容を入力してください。</p>
    <label class="form__label">メール件名</label>
    <div class="form__subject">
      <p class="form__subject__prefix">【QRation.me】</p>
      <input class="form__subject__input" v-model="postParams.subject" placeholder="件名を入力する" />
    </div>
    <p class="form__content">QRation.me ご利用のお客様<br><br>QRation.me運営事務局です。<br>いつもご利用いただき、ありがとうございます。</p>
    <textarea class="form__textarea" v-model="postParams.content" placeholder="送りたい本文を記入してください。" />
    <p class="form__footer">QRation.me運営事務局より</p>
    <v-btn rounded depressed :disabled="!canSubmit" class="form__btn" @click="submit()">送信</v-btn>
  </div>
</template>

<script>
export default {
  data () {
    return {
      postParams: {
        subject: '',
        content: ''
      }
    }
  },
  computed: {
    /**
     * @return {Boolean} メール送信可能かどうか
     */
    canSubmit () {
      return Object.values(this.postParams).every(value => value.trim().length > 0)
    }
  },
  methods: {
    /**
     * メール送信
     */
    async submit () {
      // ボタン押下の条件を満たしていなければスキップ
      this.$store.commit('setSubmitting', true)

      const payload = {
        subject: '【QRation.me】' + this.postParams.subject,
        content: this.postParams.content.replace(/\r?\n/g, '<br>'),
        to: 'users'
      }
      await this.$store.dispatch('functions/sendEmailAll', payload)

      this.$store.commit('setTelop', { show: true, msg: '全ユーザーにメールを送信しました', type: 'success' })
      this.$router.push({ name: 'home' })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/color.scss';
@import '@/assets/sass/size.scss';

.form {
  &__title {
    margin-bottom: 83px;
    font-size: 1.2rem;
    line-height: 1.4rem;
  }
  &__label {
    font-size: 1.2rem;
    line-height: 1.4rem;
  }
  &__subject {
    display: flex;
    align-items: center;
    margin-top: 5px;
    font-size: 1.6rem;
    line-height: 1.9rem;
    color: $black_color;
    text-align: center;
    &__prefix {
      margin: 0;
    }
    &__input {
      outline: none;
    }
  }
  &__content {
    margin: 0;
    margin-top: 60px;
    font-size: 1.2rem;
    line-height: 1.4rem;
  }
  &__textarea {
    width: 100%;
    height: 200px;
    padding: 20px 10px;
    margin: 0;
    margin-top: 20px;
    overflow: hidden;
    font-size: 1.6rem;
    line-height: 1.9rem;
    resize: none;
    border: 1px solid $gray_darken_color;
    outline: none;
  }
  &__footer {
    margin-top: 20px;
    font-size: 1.2rem;
    line-height: 1.4rem;
    text-align: right;
  }
  &__btn {
    display: block;
    width: 100px;
    margin: auto;
    margin-top: 60px;
    margin-bottom: 20px;
    color: white;
    &.v-btn:not(.v-btn--round) {
      height: 30px;
    }
    &.theme--light.v-btn:not(.v-btn--flat) {
      background-color: $orange_color;
    }
    &.theme--light.v-btn.v-btn--disabled:not(.v-btn--flat) {
      color: white !important;
      background-color: $orange_lighten_color !important;
    }
  }
}
</style>
